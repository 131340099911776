import { createSearch, System } from '@/modules/system';
import $app from '@/plugins/modules';
import store from '@/plugins/store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { IPagination } from '../system/types';
import { IVisitsReportSearch } from './types';

const $system = $app.module(System);

@Module({ dynamic: true, store, name: 'reports' })
export default class ReportStore extends VuexModule {
  private visitSearch: IVisitsReportSearch = {
    items: [],
    filter: createSearch(
      0, 0, null, null,
      {
        from: new Date().fromMonthStart().native(false),
        to: new Date().native(false)
      }
    )
  }

  private doctorSearch: IVisitsReportSearch = {
    items: [],
    filter: createSearch(
      0, 0, null, null,
      {
        from: new Date().fromMonthStart().native(false),
        to: new Date().native(false)
      }
    )
  }

  // ---------------------------------------------------------------------- GETTERS

  get VisitSearch(): IVisitsReportSearch {
    return this.visitSearch
  }

  get DoctorSearch(): IVisitsReportSearch {
    return this.doctorSearch
  }

  // ---------------------------------------------------------------------- MUTATIONS

  @Mutation
  setVisitSearch(value: IVisitsReportSearch) {
    if (value.filter.itemsPerPage === 9999999) {
      value.filter.itemsPerPage = -1
    }
    this.visitSearch = value;
  }

  @Mutation
  setDoctorSearch(value: IVisitsReportSearch) {
    if (value.filter.itemsPerPage === 9999999) {
      value.filter.itemsPerPage = -1
    }
    this.doctorSearch = value;
  }

  // ---------------------------------------------------------------------- ACTIONS

  @Action({ rawError: true })
  async visitReport(pagination: IPagination) {
    this.setVisitSearch(
      await $system.$module.find('/api/reports/visits', this.visitSearch, pagination)
    );
  }

  @Action({ rawError: true })
  async doctorReport(pagination: IPagination) {
    this.setDoctorSearch(
      await $system.$module.find('/api/reports/doctors', this.doctorSearch, pagination)
    );
  }
}
