import { IAppModule, ILink, IView } from '@/common/types'
import { Access } from '@/common/enums'

export default class ReportsModule implements IAppModule {
  async init(): Promise<any> {
  }

  async routes(): Promise<Array<ILink>> {
    return [

      { name: 'NVDReport', path: '/reports/nvd', component: (await import('./views/NVDReport.vue')).default, access: Access.ReportViewer },
      { name: 'VisitsReport', path: '/reports/visits', component: (await import('./views/VisitsReport.vue')).default, access: Access.ReportViewer },
      { name: 'DoctorsReport', path: '/reports/doctors', component: (await import('./views/DoctorsReport.vue')).default, access: Access.ReportViewer }

    ]
  }

  async userMenu(): Promise<Array<IView>> {
    return [
    ]
  }

  async reports(): Promise<Array<IView>> {
    return [
    ]
  }
}
