import { AppModule, VuexModuleConstructor } from '@/common/types'
import ReportStore from './ReportStore'
import ReportsModule from './ReportsModule'

export class Reports extends AppModule<ReportStore, ReportsModule> {
  public get name(): string {
    return 'reports'
  }

  protected get storeClass(): VuexModuleConstructor<ReportStore> {
    return ReportStore
  }
}

export default ReportsModule
